import React, { useMemo } from 'react';
import '../app/fragments';
import '../app/styles.scss';
import SEO from '../components/seo';
import StaffSchedule from '../content/staffSchedule.mdx';
import GuestSchedule from '../content/guestSchedule.mdx';

export default function ProgramPage({ location }) {
  const isStaff = useMemo(() => location.search.startsWith('?staff'), [location]);

  return (
    <article
      style={{
        color: 'white',
        background: `linear-gradient(179.56deg, #004040 0.13%, #004040 16.09%, #063C6A 29.76%, #063C6A 42.94%, #3E0552 74.69%, #7A0F33 87.95%, #DE3A54 104.5%, #DA5F58 109.84%)`,
        paddingBottom: '1rem',
        minHeight: '100vh'
      }}
      data-theme="form"
      className="internalProgram"
    >
      <div className="container">
        <SEO title="Internal Program" />
        <br />
        {isStaff ? <StaffSchedule /> : <GuestSchedule />}
      </div>
    </article>
  );
}
