import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Bride's airbnb`}</h1>
    <h4>{`8:30 Hair and makeup begins for wedding party`}</h4>
    <h4>{`11:00 Bride's party should be dressed`}</h4>
    <h4>{`11:15 Bride arrives at Venue`}</h4>
    <ul>
      <li parentName="ul">{`Lunch and snacks`}</li>
    </ul>
    <h4>{`11:45 Bride photos`}</h4>
    <h1>{`Venue`}</h1>
    <h4>{`12:30 Groom's party photos at venue`}</h4>
    <h4>{`1:00 Set up livestream`}</h4>
    <ul>
      <li parentName="ul">{`Facebook business stream`}</li>
      <li parentName="ul">{`Embed video stream in wedding site`}</li>
      <li parentName="ul">{`Test videos`}</li>
      <li parentName="ul">{`Go live with splash page`}</li>
    </ul>
    <h4>{`1:30 Guests arrive at Station 3`}</h4>
    <h4>{`2:00 Ceremony Begins`}</h4>
    <ul>
      <li parentName="ul">{`processional`}</li>
      <li parentName="ul">{`seating of grandparents`}</li>
      <li parentName="ul">{`seating of parents`}</li>
      <li parentName="ul">{`entering of groom and bridal party`}</li>
      <li parentName="ul">{`bridal march`}</li>
      <li parentName="ul">{`Officiation`}</li>
      <li parentName="ul">{`Glass pouring ceremony`}</li>
    </ul>
    <h4>{`2:30 Cocktail hour`}</h4>
    <ul>
      <li parentName="ul">{`Wedding photos after the ceremony`}</li>
      <li parentName="ul">{`Serving`}
        <ul parentName="li">
          <li parentName="ul">{`horderves`}</li>
          <li parentName="ul">{`coffee`}</li>
          <li parentName="ul">{`Salad`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Mc & videographer moves upstairs`}
        <ul parentName="li">
          <li parentName="ul">{`Have the projector setup for the photo slideshow`}</li>
          <li parentName="ul">{`Livestream show slideshow with be back at 3:00 message`}</li>
          <li parentName="ul">{`Announce table numbers to go take photos with masks and post to site`}</li>
        </ul>
      </li>
    </ul>
    <h4>{`3:00 wedding party arrives`}</h4>
    <ul>
      <li parentName="ul">{`Introduction to wedding party`}
        <ul parentName="li">
          <li parentName="ul">{`Best Man - Trahern Curkendall`}</li>
          <li parentName="ul">{`Groomsman - Josh Koert`}</li>
          <li parentName="ul">{`Maid of Honor - Amy Czarnik`}</li>
          <li parentName="ul">{`Bridesmaid - Julia Penny`}</li>
        </ul>
      </li>
      <li parentName="ul">{`First dances`}
        <ul parentName="li">
          <li parentName="ul">{`Bride groom dance`}</li>
          <li parentName="ul">{`Father daughter dance`}</li>
          <li parentName="ul">{`Mother son dance`}</li>
        </ul>
      </li>
      <li parentName="ul">{`After first dances play music videos on live stream`}</li>
      <li parentName="ul">{`Show splash page with message about cake cutting coming up at 4:10`}</li>
    </ul>
    <h4>{`3:10 Dinner-ish`}</h4>
    <ul>
      <li parentName="ul">{`prayer`}</li>
      <li parentName="ul">{`entree service`}</li>
    </ul>
    <h4>{`3:45`}</h4>
    <ul>
      <li parentName="ul">{`Start passing out champagne`}</li>
    </ul>
    <h4>{`4:10 Cake`}</h4>
    <ul>
      <li parentName="ul">{`Cake will be served upstairs seated`}</li>
      <li parentName="ul">{`Toast speech`}</li>
    </ul>
    <h4>{`4:55 Guest Line for Brid and groom`}</h4>
    <h4>{`5:00 Bride Groom leave`}</h4>
    <ul>
      <li parentName="ul">{`gather around wearing face masks for weird pictures on exit`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      