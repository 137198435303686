import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Program`}</h1>
    <h4>{`1:30 Guests arrive at Station 3`}</h4>
    <h4>{`2:00 Ceremony Begins`}</h4>
    <ul>
      <li parentName="ul">{`Seating of Grandparents`}</li>
      <li parentName="ul">{`Seating of Parents`}</li>
      <li parentName="ul">{`entering of groom and bridal party`}</li>
      <li parentName="ul">{`Bridal March`}</li>
      <li parentName="ul">{`Officiation`}</li>
      <li parentName="ul">{`Glass pouring ceremony`}</li>
    </ul>
    <h4>{`2:30 Cocktail hour`}</h4>
    <ul>
      <li parentName="ul">{`Wedding photos after the ceremony`}</li>
      <li parentName="ul">{`Serving horderves and coffee and salad upstairs`}</li>
    </ul>
    <h4>{`3:00 wedding party arrives`}</h4>
    <ul>
      <li parentName="ul">{`Introduction to wedding party`}
        <ul parentName="li">
          <li parentName="ul">{`Best Man - Trahern Curkendall`}</li>
          <li parentName="ul">{`Groomsman - Josh Koert`}</li>
          <li parentName="ul">{`Maid of Honor - Amy Czarnik`}</li>
          <li parentName="ul">{`Bridesmaid - Julia Penny`}</li>
        </ul>
      </li>
      <li parentName="ul">{`First dances`}
        <ul parentName="li">
          <li parentName="ul">{`Bride groom dance`}</li>
          <li parentName="ul">{`Father daughter dance`}</li>
          <li parentName="ul">{`Mother son dance`}</li>
        </ul>
      </li>
    </ul>
    <h4>{`3:10 Dinner-ish`}</h4>
    <h4>{`4:10 Cake`}</h4>
    <ul>
      <li parentName="ul">{`Cake will be served upstairs seated`}</li>
    </ul>
    <h4>{`4:55 Guest Line for Bride and groom`}</h4>
    <h4>{`5:00 Bride Groom leave`}</h4>
    <ul>
      <li parentName="ul">{`gather around wearing face masks for weird pictures on exit`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      